<template>
  <v-form ref="stepTwo" lazy-validation>
    <v-row>
      <v-col cols="12" class="body-1 font-weight-bold">
        {{ $t("e_test.addressed_to") }}
      </v-col>
      <v-col cols="12">
        <v-radio-group
          v-model="dataRequest.allClass.isAllClass"
          :mandatory="true"
          :readonly="!isEdit"
          column
        >
          <v-radio
            v-if="isEdit"
            :label="$t('e_test.all_grade') + ' ' + info.header.grade_name"
            :value="true"
          />
          <v-row v-if="isEdit" class="ml-5">
            <v-col cols="12" sm="3">
              <DatePicker
                :label="$t('e_test.start_date')"
                :minDate="todayDate"
                :disabled="!dataRequest.allClass.isAllClass"
                @getValue="v => (dataRequest.allClass.startDate = v)"
              />
            </v-col>
            <v-col cols="12" sm="3">
              <DatePicker
                :label="$t('e_test.end_date')"
                :disabled="
                  !dataRequest.allClass.isAllClass ||
                  !dataRequest.allClass.startDate
                    ? true
                    : false
                "
                :minDate="dataRequest.allClass.startDate"
                @getValue="v => (dataRequest.allClass.endDate = v)"
              />
            </v-col>
            <v-col cols="12" sm="3">
              <TimePicker
                :label="$t('e_test.start_hours')"
                :isDisabled="!dataRequest.allClass.isAllClass"
                @value="v => (dataRequest.allClass.startHours = v)"
              />
            </v-col>
            <v-col cols="12" sm="3">
              <TimePicker
                :label="$t('e_test.end_hours')"
                :isDisabled="
                  !dataRequest.allClass.isAllClass ||
                  !dataRequest.allClass.startHours
                    ? true
                    : false
                "
                :min="minTime(dataRequest.allClass)"
                @value="v => (dataRequest.allClass.endHours = v)"
              />
            </v-col>
          </v-row>
          <v-radio :label="$t('e_test.some_class')" :value="false" />
          <v-data-table
            v-model="dataRequest.someClass"
            :headers="tableHeaders"
            :items="dataClass"
            :items-per-page="dataClass.length"
            show-select
            item-key="id"
            disable-pagination
            hide-default-footer
            class="elevation-0"
            @item-selected="changeSelected"
          >
            <template #header.data-table-select />
            <template v-if="!isEdit" #item.data-table-select="{ item }">
              <v-icon v-if="item.startDate">mdi-checkbox-marked</v-icon>
              <v-icon v-else>mdi-checkbox-blank-outline</v-icon>
            </template>
            <template
              v-if="isEdit"
              #item.data-table-select="{ item, isSelected, select }"
            >
              <v-simple-checkbox
                v-if="item.status !== 'PUBLISH'"
                :value="isSelected"
                @input="select($event)"
              />
            </template>
            <template #item.startDate="{item}">
              <v-text-field
                v-if="!isEdit"
                :value="formatDate(item.startDate)"
                dense
                outlined
                disabled
                hide-details
                readonly
              />
              <DatePicker
                v-else
                :value="item.startDate"
                :label="$t('e_test.start_date')"
                :minDate="todayDate"
                :disabled="disabledPicker(item)"
                @getValue="v => (item.startDate = v)"
              />
            </template>
            <template #item.endDate="{item}">
              <v-text-field
                v-if="!isEdit"
                :value="formatDate(item.endDate)"
                dense
                outlined
                disabled
                hide-details
                readonly
              />
              <DatePicker
                v-else
                :value="item.endDate"
                :label="$t('e_test.end_date')"
                :minDate="item.startDate"
                :disabled="
                  disabledPicker(item) || !item.startDate ? true : false
                "
                @getValue="v => (item.endDate = v)"
              />
            </template>
            <template #item.startHours="{item}">
              <v-text-field
                v-if="!isEdit"
                :value="item.startHours"
                dense
                outlined
                disabled
                hide-details
                readonly
              />
              <TimePicker
                v-else
                :initValue="item.startHours"
                :label="$t('e_test.start_hours')"
                :isDisabled="disabledPicker(item)"
                @value="v => (item.startHours = v)"
              />
            </template>
            <template #item.endHours="{item}">
              <v-text-field
                v-if="!isEdit"
                :value="item.endHours"
                dense
                outlined
                disabled
                hide-details
                readonly
              />
              <TimePicker
                v-else
                :initValue="item.endHours"
                :label="$t('e_test.end_hours')"
                :isDisabled="
                  disabledPicker(item) || !item.startHours ? true : false
                "
                :min="minTime(item)"
                @value="v => (item.endHours = v)"
              />
            </template>
          </v-data-table>
        </v-radio-group>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import i18n from "@/i18n";
import moment from "moment/moment";
moment.locale("id");

export default {
  props: {
    dataClass: Array,
    dataRequest: Object,
    isEdit: Boolean,
    edit: Boolean,
    reset: Boolean,
    info: Object
  },
  components: {
    TimePicker: () => import("@/components/TimePicker"),
    DatePicker: () => import("@/components/DatePicker")
  },
  watch: {
    reset(value) {
      if (value) {
        this.$refs.stepTwo.reset();
        this.$refs.stepTwo.resetValidation();
      }
    }
  },
  data() {
    return {
      todayDate: new Date().toISOString().slice(0, 10),
      tableHeaders: [
        {
          text: i18n.t("app.class"),
          align: "left",
          value: "name",
          sortable: false
        },
        {
          text: i18n.t("e_test.start_date"),
          value: "startDate",
          sortable: false
        },
        {
          text: i18n.t("e_test.end_date"),
          value: "endDate",
          sortable: false
        },
        {
          text: i18n.t("e_test.start_hours"),
          value: "startHours",
          sortable: false
        },
        {
          text: i18n.t("e_test.end_hours"),
          value: "endHours",
          sortable: false
        }
      ]
    };
  },
  methods: {
    formatDate(date) {
      if (date) return moment(date).format("DD MMM YYYY");
      else return null;
    },
    disabledPicker(item) {
      const condition = this.dataRequest.someClass.find(r => r.id == item.id);
      return this.dataRequest.allClass.isAllClass || !condition ? true : false;
    },
    changeSelected(item) {
      this.$emit("onChangeSelect", item);
    },
    minTime: item => (item.startDate === item.endDate ? item.startHours : null)
  }
};
</script>
